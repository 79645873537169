function _registerEvent(target, eventType, cb) {
  if (target.addEventListener) {
    target.addEventListener(eventType, cb);
    return {
      remove() {
        target.removeEventListener(eventType, cb);
      },
    };
  }
  target.attachEvent(eventType, cb);
  return {
    remove() {
      target.detachEvent(eventType, cb);
    },
  };
}
/**
  * uri 打开客户端的uri hnszjc://
  * failCb 打开客户端失败回调
  * successCb 打开客户端成功回调
  */
export const openUriWithInputTimeoutHack = (uri, failCb, successCb) => {
  const target = document.createElement('input');
  target.style.width = '0';
  target.style.height = '0';
  target.style.position = 'fixed';
  target.style.top = '0';
  target.style.left = '0';
  document.body.appendChild(target);

  target.focus();
  let timeout;
  const handler = _registerEvent(target, 'blur', () => {
    successCb && successCb();
    handler.remove();
    clearTimeout(timeout);
    document.body.removeChild(target);
  });


  // will trigger onblur
  window.location.href = uri;

  // Note: timeout could vary as per the browser version, have a higher value
  timeout = setTimeout(() => {
    failCb && failCb();
    handler.remove();
    document.body.removeChild(target);
  }, 1000);
};

export const a = () => { };
