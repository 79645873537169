<template>
  <div class="jiaocai">
    <heads />
    <div class="jiaocaibanner">
      <div class="jc-c">
        <div class="c1">河南省中小学数字教材服务平台</div>
        <div class="c2">全学科、全学段、全版本的数字教材</div>
        <img :src="downClient"
          class="downClient"
          alt=""
          @click="downClientpath">
      </div>
    </div>
    <!-- 数字教材 -->
    <div class="book-list">
      <filters @getList="getList" />
      <!-- 教材列表 -->
      <div class="book-list-content">
        <div v-if="!isHaveBook">
          <img :src="nobook"
            class="nobook"
            alt="">
          <div class="nobook-desc">暂无相关教材</div>
        </div>
        <!-- 教材-->
        <div v-for="(item ,k) in dataList"
          :key="k"
          class="book-books">
          <div class="book-top"
            @click="isDown">
            <img :src="item.imageUrl"
              class="book-img"
              alt="">
          </div>
          <div class="book-name">{{item.title}}</div>
          <div class="book-press">{{item.supplierName}}</div>
        </div>
        <el-pagination v-if="total > pageSize"
          :page-sizes="[12, 24, 48, 96]"
          :page-size="pageSize"
          :current-page="page"
          :total="total"
          background
          class="pagination"
          layout="total, prev, pager, next, sizes"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </div>
    </div>
    <!-- <contant /> -->
    <foots />
  </div>
</template>
<script>
import _ from 'lodash';
import { get } from 'util/request';
import { openUriWithInputTimeoutHack } from '../common/isInitClient';
import nobook from '../../assets/pic/nobook.svg';
import downClient from '../../assets/pic/downClient.png';
import { goodsGet, getCommentCache } from '../api.js';
import filters from './filtertemp.vue';
// import contant from '../common/contant';
import heads from '../common/head';
import foots from '../common/foot';


export default {
  components: {
    heads,
    foots,
    filters,
    // contant,
  },
  data() {
    const { szjcDown } = window.config;
    return {
      nobook,
      downClient,
      // 下载数字教材地址
      szjcDown,
      hnszjcDownload: window.config.hnszjcDownload,
      // 年级
      grade: '',
      gradeList: [],
      // 科目
      subject: '',
      subjectList: [],
      // 出版社
      version: '',
      versionList: [],
      // 分册
      volume: '',
      volumeList: [],
      array: [],
      dataList: [],
      // 分页
      pageSize: 12,
      page: 1,
      total: 0,
      isHaveBook: true,
      gaozhong: 'a,b,c',
    };
  },
  created() {
    this.init();
  },
  methods: {
    getArgs() {
      let args = {};
      // 年级
      if (this.grade) {
        args = {
          ...args,
          grade: this.grade,
        };
      }
      // 科目
      if (this.subject) {
        args = {
          ...args,
          subject: this.subject,
        };
      }
      // 出版社
      if (this.version) {
        args = {
          ...args,
          code: this.version,
        };
      }
      // 分册
      if (this.volume) {
        args = {
          ...args,
          volume: this.volume,
        };
      }
      this.getList(args, '1');
    },
    getList(argstemp = {}, type) {
      if (!type) {
        const { subject = '', grade = '', version = '', volume = '' } = argstemp || {};
        this.subject = subject;
        this.grade = grade;
        this.version = version;
        this.volume = volume;
        this.page = 1;
      }
      const args = {
        ...argstemp,
        page: this.page,
        pageSize: this.pageSize,
        status: '1',
      };

      goodsGet(args).then(({ data, err }) => {
        if (!err) {
          const { data: tableData = [], total } = data || {};
          this.isHaveBook = tableData.length >= 1;
          this.total = total;
          this.dataList = _.map(tableData, val => {
            let title = '';
            title += (_.find(this.gradeList, { code: _.parseInt(val.grade || '') }) || {}).name || '';
            title += ' ';
            title += (_.find(this.subjectList, { code: val.subject || '' }) || {}).name || '';
            title += ' ';
            title += (_.find(this.volumeList, { code: _.parseInt(val.volume || '') }) || {}).name || '';
            const supplierName = (_.find(this.versionList, { code: val.version || '' }) || {}).name || '';
            return { ...val, title: this.gaozhong.indexOf(val.grade) === -1 ? title : val.name, supplierName };
          });
        }
      });
    },
    /* 获取字典信息 */
    init() {
      getCommentCache({ term: 'common_paper' }).then(v => {
        const { data: [{ data = [] }] } = v.data || [];
        this.gradeList = (data[2] || {}).data || [];
        this.subjectList = (data[3] || {}).data || [];
        this.versionList = (data[4] || {}).data || [];
        this.volumeList = (data[5] || {}).data || [];
        this.getArgs();
      });
    },
    /* 分页 */
    handleCurrentChange(page) {
      this.page = page;
      this.getArgs();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getArgs();
    },
    isDown() {
      // 判断客户端是否已下载
      openUriWithInputTimeoutHack(`hnszjc://open?token=${this.cookie}&appId=${this.appId}`,
        () => {
          this.$confirm('下载数字教材客户端，打开「数字教材」模块，激活教材后查看内容', '下载数字教材客户端', {
            distinguishCancelAndClose: true,
            confirmButtonText: '下载客户端',
            cancelButtonText: '取消',
          })
            .then(() => {
              // 未下载
              this.downClientpath();
            })
            .catch(() => { });
        },
        () => {
          // this.$confirm('打开数字教材客户端，在「数字教材」模块，激活教材后查看内容', '提示', {
          //   distinguishCancelAndClose: true,
          //   confirmButtonText: '打开客户端',
          //   cancelButtonText: '取消',
          // })
          //   .then(() => { })
          //   .catch(() => { });
          //   已下载,直接打开
          // window.open('hnszjc://open', '_self');
        });
    },
    /* 下载客户端 */
    downClientpath() {
      // window.open(this.szjcDown, '_target');
      const num = _.parseInt(Math.random(0, 1) * 1000);
      get(`${this.hnszjcDownload.hnszjcUrl}?${num}`).then(({ data, err }) => {
        if (!err) {
          const { version } = data;
          const downUrlPC = `${this.hnszjcDownload.downUrl}${version}/${this.hnszjcDownload.name}`;
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = downUrlPC;
          a.download = this.hnszjcDownload.name;
          a.style.display = 'none';
          a.click();
        }
      });
    },
  },
};
</script>
<style lang="less">
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #1980ff;
  color: #fff;
}
.el-pagination.is-background .el-pager li:not(.disabled) {
  &:hover {
    background: #1980ff;
    color: #fff;
  }
}
.el-pagination .el-select .el-input .el-input__inner {
  &:hover {
    border-color: #1980ff;
  }
}
// .el-message {
//   top: 80px !important;
// }
</style>
<style lang='less' scoped>
@import '../../assets/css/books.css';
.jiaocai {
  background: #fafafa;
}
.jc-c {
  width: 1200px;
  padding-top: 86px;
  padding-left: 27px;
  margin: auto;
}
.c1 {
  height: 67px;
  font-size: 48px;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 600;
  text-align: left;
  color: #ffffff;
  line-height: 67px;
}
.c2 {
  height: 33px;
  font-size: 24px;
  text-align: left;
  color: #ffffff;
  line-height: 33px;
  margin-top: 16px;
}
.downClient {
  width: 240px;
  height: 64px;
  background: linear-gradient(90deg, #ff6c00, #ff3603);
  border-radius: 32px;
  box-shadow: 0px 0px 16px 0px rgba(112, 25, 0, 0.1);
  display: block;
  margin-top: 48px;
  cursor: pointer;
}
.jiaocaibanner {
  background: url('../../assets/pic/jiaocaibanner.png') no-repeat center;
  width: 100%;
  height: 400px;
  margin-top: 143px;
}
.book-list {
  padding-top: 24px;
}
</style>
