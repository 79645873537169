<template>
  <!-- 书架筛选条件 -->
  <div class="book-list-top">
    <ul class="select-list">
      <li class="select-check">
        <div class="title-types">
          <span class="select-name margin-top-16">学科</span>
          <span class="select-line margin-top-16">|</span>
        </div>
        <div class="title-contents">
          <span :class="`select-all margin-top-16 ${ subject ? '' : 'check-color'}`"
            @click="subjectClick('')">全部</span>
          <span v-for="(item, key) in subjectList"
            :key="key"
            :class="`select-content margin-top-16 ${item.code === subject ? 'check-color' : ''}`"
            @click="subjectClick(item.code)">{{item.title}}</span>
        </div>
        <div class="hide-show"
          @click="isSq = !isSq">
          <img :src="isSq ? zhankai :shouqi"
            alt=""
            class="hide-show-img">
          <span class="hide-show-title">{{isSq? '展开' : '收起'}}</span>
        </div>
      </li>
      <li v-if="!isSq"
        class="select-check">
        <div class="title-types">
          <span class="select-name margin-top-16">年级</span>
          <span class="select-line margin-top-16">|</span>
        </div>
        <div class="title-contents">
          <span :class="`select-all margin-top-16 ${ grade ? '' : 'check-color'}`"
            @click="gradeClick('')">全部</span>
          <span v-for="(item, key) in gradeList"
            :key="key"
            :class="`select-content margin-top-16 ${item.code === grade ? 'check-color' : ''}`"
            @click="gradeClick(item.code)">{{item.title}}</span>
        </div>
      </li>
      <li v-if="!isSq"
        class="select-check">
        <div class="title-types">
          <span class="select-name margin-top-16">版本</span>
          <span class="select-line margin-top-16">|</span>
        </div>
        <div class="title-contents">
          <span :class="`select-all margin-top-16 ${ version ? '' : 'check-color'}`"
            @click="versionClick('')">全部</span>
          <span v-for="(item, key) in versionList"
            :key="key"
            :class="`select-content margin-top-16 ${item.code === version ? 'check-color' : ''}`"
            @click="versionClick(item.code)">{{item.title}}</span>
        </div>
      </li>
      <li v-if="!isSq"
        class="select-check">
        <div class="title-types">
          <span class="select-name margin-top-16">分册</span>
          <span class="select-line margin-top-16">|</span>
        </div>
        <div class="title-contents">
          <span :class="`select-all margin-top-16 ${ volume ? '' : 'check-color'}`"
            @click="volumeClick('')">全部</span>
          <span v-for="(item, key) in volumeList"
            :key="key"
            :class="`select-content margin-top-16 ${item.code === volume ? 'check-color' : ''}`"
            @click="volumeClick(item.code)">{{item.title}}</span>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import _ from 'lodash';
import { getInit } from '../api.js';

import shouqi from '../../assets/pic/shouqi.png';
import zhankai from '../../assets/pic/zhankai.png';

export default {
  props: {},
  data() {
    return {
      zhankai,
      shouqi,
      // 科目
      subject: '',
      subjectList: [],
      // 年级
      grade: '',
      gradeList: [],
      // 出版社
      version: '',
      versionList: [],
      // 分册
      volume: '',
      volumeList: [],
      // 是否收起
      isSq: true,
      // 所有出版社(有排序)
      versionAll: [],
    };
  },

  created() {
    this.init();
  },
  methods: {
    init() {
      getInit({}).then(({ data }) => {
        const { data: subjectList, all } = data || {};
        this.subjectList = subjectList;
        this.versionAll = all;
        // 获取年级
        this.getGradeList();
        // 获取出版社
        this.getVersionList();
        // 获取分册
        this.getVolumeList();
      });
    },
    /* 科目 */
    subjectClick(code) {
      this.subject = code;
      this.grade = '';
      this.version = '';
      this.volume = '';
      this.getGradeList();
      this.getVersionList();
      this.getVolumeList();
      this.getList();
    },
    /* 年级 */
    gradeClick(code) {
      this.grade = code;
      // 把科目出版社分册设为空(这个年级下不一定有值)
      this.version = '';
      this.volume = '';
      this.getVersionList();
      this.getVolumeList();
      this.getList();
    },
    /* 出版社 */
    versionClick(code) {
      this.version = code;
      this.volume = '';
      this.getVolumeList();
      this.getList();
    },
    /* 分册 */
    volumeClick(code) {
      this.volume = code;
      this.getList();
    },
    /* 获取年级(在学科下) */
    getGradeList() {
      if (this.subject) {
        // 学科存在
        const { grade: gradeList = [] } = _.find(this.subjectList, { code: this.subject }) || {};
        this.gradeList = gradeList;
      } else {
        // 学科选了全部
        const tempGrade = _.reduce(this.subjectList, (res, gradeVal) => {
          const { grade: gradeList1 = [] } = gradeVal || {};
          return [...res, ...gradeList1];
        }, []);
        this.gradeList = _.uniqBy(tempGrade, 'code');
      }
    },

    /* 获取出版社(在年级下) */
    getVersionList() {
      let gradeList = [];
      let versionList = [];
      if (this.subject) {
        const { grade: tempGrade = [] } = _.find(this.subjectList, { code: this.subject }) || {};
        gradeList = tempGrade;
      } else {
        gradeList = _.reduce(this.subjectList, (res, gradeVal) => {
          const { grade: gradeList1 = [] } = gradeVal || {};
          return [...res, ...gradeList1];
        }, []);
      }
      if (this.grade) {
        gradeList = _.filter(gradeList, v => v.code === this.grade);
      }
      versionList = _.reduce(gradeList, (res, gradeVal) => {
        const { publishers: versionList3 = [] } = gradeVal || {};
        return [...res, ...versionList3];
      }, []);
      this.versionList = _.uniqBy(versionList, 'code');
    },
    /* 获取分册(在出版社下) */
    getVolumeList() {
      // 出版社存在
      let versionList = [];
      let gradeList = [];
      if (this.subject) {
        const { grade: tempGrade = [] } = _.find(this.subjectList, { code: this.subject }) || {};
        gradeList = tempGrade;
      } else {
        gradeList = _.reduce(this.subjectList, (res, gradeVal) => {
          const { grade: gradeList1 = [] } = gradeVal || {};
          return [...res, ...gradeList1];
        }, []);
      }
      if (this.grade) {
        gradeList = _.filter(gradeList, v => v.code === this.grade);
      }
      versionList = _.reduce(gradeList, (res, gradeVal) => {
        const { publishers: versionList3 = [] } = gradeVal || {};
        return [...res, ...versionList3];
      }, []);
      if (this.version) {
        versionList = _.filter(versionList, v => v.code === this.version);
      }
      const tempVolume = _.reduce(versionList, (res, versionVal) => {
        const { fascicule: volumeList1 = [] } = versionVal || {};
        return [...res, ...volumeList1];
      }, []);
      this.volumeList = _.orderBy(_.uniqBy(tempVolume, 'code'), 'code');
    },
    /* 获取书架列表 */
    getList() {
      let args = {};
      // 年级
      if (this.grade) {
        args = {
          ...args,
          grade: this.grade,
        };
      }
      // 科目
      if (this.subject) {
        args = {
          ...args,
          subject: this.subject,
        };
      }
      // 出版社
      if (this.version) {
        args = {
          ...args,
          version: this.version,
        };
      }
      // 分册
      if (this.volume) {
        args = {
          ...args,
          volume: this.volume,
        };
      }
      this.$emit('getList', args);
    },
  },
};
</script>
<style lang="less" scoped>
@import '../../assets/css/bookshelf.less';
.select-check {
  position: relative;
}
.hide-show {
  position: absolute;
  right: 24px;
  top: 16px;
  cursor: pointer;
}
.hide-show-img {
  display: inline-block;
  width: 16px;
  height: 10px;
  opacity: 1;
  margin-right: 8px;
}
.hide-show-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}
</style>
