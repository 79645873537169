var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jiaocai" },
    [
      _c("heads"),
      _c("div", { staticClass: "jiaocaibanner" }, [
        _c("div", { staticClass: "jc-c" }, [
          _c("div", { staticClass: "c1" }, [
            _vm._v("河南省中小学数字教材服务平台")
          ]),
          _c("div", { staticClass: "c2" }, [
            _vm._v("全学科、全学段、全版本的数字教材")
          ]),
          _c("img", {
            staticClass: "downClient",
            attrs: { src: _vm.downClient, alt: "" },
            on: { click: _vm.downClientpath }
          })
        ])
      ]),
      _c(
        "div",
        { staticClass: "book-list" },
        [
          _c("filters", { on: { getList: _vm.getList } }),
          _c(
            "div",
            { staticClass: "book-list-content" },
            [
              !_vm.isHaveBook
                ? _c("div", [
                    _c("img", {
                      staticClass: "nobook",
                      attrs: { src: _vm.nobook, alt: "" }
                    }),
                    _c("div", { staticClass: "nobook-desc" }, [
                      _vm._v("暂无相关教材")
                    ])
                  ])
                : _vm._e(),
              _vm._l(_vm.dataList, function(item, k) {
                return _c("div", { key: k, staticClass: "book-books" }, [
                  _c(
                    "div",
                    { staticClass: "book-top", on: { click: _vm.isDown } },
                    [
                      _c("img", {
                        staticClass: "book-img",
                        attrs: { src: item.imageUrl, alt: "" }
                      })
                    ]
                  ),
                  _c("div", { staticClass: "book-name" }, [
                    _vm._v(_vm._s(item.title))
                  ]),
                  _c("div", { staticClass: "book-press" }, [
                    _vm._v(_vm._s(item.supplierName))
                  ])
                ])
              }),
              _vm.total > _vm.pageSize
                ? _c("el-pagination", {
                    staticClass: "pagination",
                    attrs: {
                      "page-sizes": [12, 24, 48, 96],
                      "page-size": _vm.pageSize,
                      "current-page": _vm.page,
                      total: _vm.total,
                      background: "",
                      layout: "total, prev, pager, next, sizes"
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange
                    }
                  })
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _c("foots")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }